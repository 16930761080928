/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #e3f2fd; /* Light blue background */
  color: #333;
  line-height: 1.6;
  text-align: center;
  padding: 0;
  margin: 0;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;
}

h1, h2, h3 {
  color: #0d47a1; /* Dark blue for headings */
}

p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

button {
  background-color: #0d47a1;
  color: white;
  border: 2px solid #0d47a1;
  padding: 12px 20px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  margin-top: 20px;
}

button:hover {
  background-color: white;
  color: #0d47a1;
  transform: translateY(-2px);
}

section {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
  margin-bottom: 40px;
}

header, .about-me, .experience {
  transition: all 0.3s ease;
}

header {
  background-color: #e3f2fd; /* Light blue background for header */
  border-bottom: 3px solid #0d47a1; /* Blue border for header */
  padding-bottom: 20px;
}

h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

p {
  font-size: 1.2rem;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

h3 {
  font-size: 2rem;
  color: #0d47a1;
}

ul {
  list-style-type: none;
  margin-top: 20px;
}

ul li {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

section:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .App {
    padding: 20px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}
